/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import * as SurveyRender from 'survey-react';

import 'survey-react/survey.css';
import 'survey-react/modern.css';
import 'select2/dist/css/select2.min.css';

const StyleWrapper = styled.div`
 & .sv_q_title {
    margin: 1em 1em;
 }
 & .sv_q_description {
  margin-top: 0; 
  margin: 0.25em 1em;
 }
 & .sv_main  button {
   min-width: 0;
 }

 & h1 {
  font-size: 50px;
  font-family: piazzolla;
  font-weight: 600;
 }

 & h2 {
  font-size: 32px;
  font-family: piazzolla;
  font-weight: 600;
 } 
 & h3 {
  font-size: 35px;
  font-family: piazzolla;
  font-weight: #444444
 } 
`;

export default ({
  model = {}, onSubmit, onChange, data, ...props
}) => {
  const [surveyModel, setSurveyModel] = useState();

  const widgets = require('surveyjs-widgets');
  if (!_.isEmpty(widgets)) {
    require('select2');
    widgets.select2(SurveyRender);
    widgets.select2tagbox(SurveyRender);
    widgets.ckeditor(SurveyRender);
  }
  const fetchModel = async () => {
    const response = await fetch(model, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    const survey = new SurveyRender.Model(json);
    setSurveyModel(survey);
  };

  useEffect(() => {
    if (typeof model === 'string') {
      fetchModel();
    } else {
      const survey = new SurveyRender.Model(model);
      setSurveyModel(survey);
    }
  },
  [model]);
  if (!surveyModel) {
    return null;
  }

  SurveyRender.StylesManager.applyTheme('stone');
  return (
    <StyleWrapper>
      <SurveyRender.Survey
        data={data}
        onComplete={onSubmit}
        onValueChanged={onChange}
        model={surveyModel}
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </StyleWrapper>
  );
};
